import React from "react";
import ReactDOM from "react-dom";
import "./i18n";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

function Main() {
    return (
        <BrowserRouter>
            <App />
            <ToastContainer />
        </BrowserRouter>
    );
}

ReactDOM.render(<Main />, document.getElementById("root"));
serviceWorker.unregister();
