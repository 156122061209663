// import React from "react";
// import { BrowserRouter } from "react-router-dom";

// import { Router } from "@components/Router";

// export default function App() {
//     return (
//         <BrowserRouter>
//             <Router />
//         </BrowserRouter>
//     );
// }

import React from "react";
import { useLocation } from "react-router-dom";
import { Router } from "@components/Router";

export default function App(): any {
    const location = useLocation();
    const baseUrl = `${window.location.protocol}//${window.location.host}`;
    const uidUserEntity = localStorage.getItem("Uid_Entitie_User");
    const [isLoading, setIsLoading] = React.useState(
        !(location.pathname === "login" || location.pathname === "/")
    );
    const fetchListUidEntityBeta = async () => {
        try {
            const url = `${
                process.env.REACT_APP_UUID_BETA
            }?cacheBuster=${Date.now()}`;
            const response = await fetch(`${url}`);
            if (!response.ok) {
                throw new Error(
                    `Erreur lors du chargement des données: ${response.status} ${response.statusText}`
                );
            }
            const data = await response.json();

            return data;
        } catch (error) {
            // eslint-disable-next-line
            console.error(
                "Erreur lors du chargement de listUidEntity :",
                error
            );
            return [];
        }
    };

    React.useEffect(() => {
        console.log(
            uidUserEntity,
            process.env.REACT_APP_BETA !== process.env.REACT_APP_PROD,
            process.env.REACT_APP_BETA
        );
        if (
            uidUserEntity &&
            process.env.REACT_APP_BETA !== process.env.REACT_APP_PROD
        ) {
            fetchListUidEntityBeta().then((UidEntityBeta) => {
                const isUserBeta = UidEntityBeta.some(
                    (item: any) => item.uuidEntity === uidUserEntity
                );

                const redirectTo = isUserBeta
                    ? process.env.REACT_APP_BETA
                    : process.env.REACT_APP_PROD;

                if (redirectTo && redirectTo !== baseUrl) {
                    localStorage.clear();
                    const newUrl = `${redirectTo}`;
                    try {
                        window.location.replace(newUrl);
                    } catch (error) {
                        // eslint-disable-next-line
                        console.error("Erreur lors de la redirection :", error);
                    }
                }
                setIsLoading(false);
            });
        } else {
            setIsLoading(false);
        }
        // eslint-disable-next-line
    }, [uidUserEntity]);

    return isLoading ? null : <Router />;
}
